import { useState } from "react";
import Header from "./../Components/Header";
import { useAuth } from "../utils/auth";
import { TextField, Button, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { changePassword } from "../utils/api";
import { useNavigate } from "react-router-dom";
import PageContainer from "../Components/PageContainer";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    oldPassword: "",
    password: "",
    passwordConfirm: "",
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { currentUserId, logout } = useAuth();
  const theme = useTheme();

  const handleFormChange = (event) => {
    setFormValue((previousValue) => ({
      ...previousValue,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!formValue.password || !formValue.oldPassword) {
      setError("Informations manquantes");
      return;
    }

    if (
      !formValue.password.match(
        "^(?=.*[0-9])(?=.*[-!@#$^&*])[a-zA-Z0-9-!@#$^&*]{6,30}$"
      )
    ) {
      setError(
        "Le mot de passe est invalide. Votre mot de passe doit comporter entre 6 et 30 caractères, " +
          "et inclure au moins un chiffre et un caractère spécial (-!@#$^&*)."
      );
      return;
    }

    if (formValue.password !== formValue.passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    if (formValue.password === formValue.oldPassword) {
      setError(
        "Le nouveau mot de passe doit être différent du mot de passe précédent."
      );
      return;
    }
    setError(false);

    changePassword(currentUserId, formValue.oldPassword, formValue.password)
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          navigate("/profile");
        }, 1500);
      })
      .catch((err) => {
        if (
          err.response.status === 401 &&
          err.response.data === "Invalid password"
        ) {
          setError("Mot de passe invalide.");
        } else if (err.response.status === 401) {
          logout();
        } else {
          setError("Désolé, une erreur est survenue.");
        }
      });
  };

  if (success) {
    return (
      <>
        <Header />
        <PageContainer size={"small"}>
          <Alert severity="success" style={{ marginTop: theme.spacing(4) }}>
            Mot de passe modifié avec succès.
          </Alert>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <PageContainer size={"small"}>
        <form value={formValue} onChange={handleFormChange} onSubmit={onSubmit}>
          <TextField
            variant="standard"
            label="Mot de passe"
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            name="oldPassword"
            type="password"
          />
          <TextField
            variant="standard"
            label="Nouveau mot de passe"
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            name="password"
            type="password"
          />
          <TextField
            variant="standard"
            label="Confirmer le mot de passe"
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            name="passwordConfirm"
            type="password"
          />
          {error && (
            <Alert severity="error" style={{ marginTop: theme.spacing(4) }}>
              {error}
            </Alert>
          )}
          <Button
            style={{ marginTop: theme.spacing(4) }}
            variant="outlined"
            type="submit"
          >
            Modifier le mot de passe
          </Button>
        </form>
      </PageContainer>
    </>
  );
}
