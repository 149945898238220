import { useState } from "react";
import { Link } from "react-router-dom";
import { login, googleAuth, facebookAuth } from "./../../utils/api";
import {
  Button,
  Alert,
  TextField,
  Divider,
  Typography,
  LinearProgress,
  Stack,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../utils/auth";
import styled from "@emotion/styled";
import PasswordInput from "./../PasswordInput";
import FacebookLogo from "./facebook_logo.svg";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleLogo from "./google_logo.svg";

const LoginSocialButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #dadce0;
  color: black;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  cursor: pointer;
  transition: background-color 0.218s, border-color 0.218s;
  &:hover {
    background-color: #e9fcef;
    border-color: ##7deda1;
  }
`;

export default function LoginForm() {
  const theme = useTheme();
  const { updateAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [socialLoginLoading, setSocialLoginLoading] = useState(false);
  const [emailLoginLoading, setEmailLoginLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setError("");
    if (!email) {
      setError("Adresse e-mail invalide");
      return;
    }

    if (!password) {
      setError("Mot de passe invalide");
      return;
    }
    setEmailLoginLoading(true);
    login(email, password)
      .then((res) => {
        updateAuth(res.data);
      })
      .catch((err) => {
        setError("Adresse e-mail ou mot de passe invalide.");
      })
      .finally(() => {
        setEmailLoginLoading(false);
      });
  };

  const onGoogleLoginSuccess = (tokenResponse) => {
    setSocialLoginLoading(true);
    setError("");
    googleAuth(tokenResponse.access_token)
      .then((res) => {
        updateAuth(res.data);
      })
      .catch((err) => {
        setError("Erreur lors de la connexion avec Google.");
      })
      .finally(() => {
        setSocialLoginLoading(false);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: (err) => {
      setError("Erreur lors de la connexion avec Google.");
    },
    flow: "implicit",
    scope: "profile email",
  });

  const onFacebookLoginSuccess = (response) => {
    setSocialLoginLoading(true);
    setError("");

    facebookAuth(response.accessToken)
      .then((res) => {
        updateAuth(res.data);
      })
      .catch((err) => {
        setError("Erreur lors de la connexion avec Facebook.");
      })
      .finally(() => {
        setSocialLoginLoading(false);
      });
  };

  const facebookLogin = () => {
    window.FB.login((res) => {
      if (res.authResponse) {
        onFacebookLoginSuccess(res.authResponse);
      } else {
        setError("Erreur lors de la connexion avec Facebook.");
      }
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          label="Adresse e-mail"
          variant="standard"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          value={email}
          name="email"
          fullWidth
        />
        <PasswordInput
          label="Mot de passe"
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          fullWidth
          name="password"
          style={{ marginTop: theme.spacing(2) }}
        />

        {!emailLoginLoading && !socialLoginLoading && (
          <Button
            style={{ marginTop: theme.spacing(3) }}
            variant="outlined"
            type="submit"
            disabled={socialLoginLoading || emailLoginLoading}
          >
            Se connecter
          </Button>
        )}

        {emailLoginLoading && (
          <LinearProgress sx={{ marginTop: 2, height: "2px" }} />
        )}
      </form>

      {!!error && (
        <Alert style={{ marginTop: theme.spacing(2) }} severity="error">
          {error}
        </Alert>
      )}

      {!emailLoginLoading && !socialLoginLoading && (
        <>
          <div
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <Link
              to="reset-password"
              style={{
                font: "inherit",
                color: "black",
                textDecoration: "none",
                fontSize: "0.9rem",
              }}
            >
              <ResetPasswordLink>Mot de passe oublié ?</ResetPasswordLink>
            </Link>
          </div>
          <Divider sx={{ marginTop: theme.spacing(2) }}>
            <Typography variant="body2" color="textSecondary">
              Connectez vous avec
            </Typography>
          </Divider>
          <Stack direction={"row"} spacing={1} sx={{ marginTop: 1 }}>
            <LoginSocialButton onClick={googleLogin}>
              <img src={GoogleLogo} alt="Google Logo" width={"22px"} />
            </LoginSocialButton>
            <LoginSocialButton onClick={facebookLogin}>
              <img src={FacebookLogo} alt="Google Logo" width={"20px"} />
            </LoginSocialButton>
          </Stack>
        </>
      )}

      {socialLoginLoading && (
        <LinearProgress sx={{ marginTop: 2, height: "2px" }} />
      )}
    </>
  );
}

const ResetPasswordLink = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;
