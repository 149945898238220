import axios from "axios";
import useStore from "./store";

const getToken = useStore.getState().getToken;
const setToken = useStore.getState().setToken;

const jwtInstance = axios.create();
jwtInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: "Bearer " + getToken(),
  };

  return config;
});

jwtInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    if (response?.status === 401) {
      return refreshToken()
        .then((res) => {
          setToken(res.data.token);
          config.headers = {
            Authorization: "Bearer " + res.data.token,
          };

          return axios(config)
            .then((res) => {
              return Promise.resolve(res);
            })
            .catch((err) => {
              setToken(null);
              window.location.href = "/";
            });
        })
        .catch((err) => {
          setToken(null);
          window.location.href = "/";
        });
    } else {
      return Promise.reject(error);
    }
  }
);

const API_URL = process.env.REACT_APP_API_URL;

export const refreshToken = () => {
  return axios.get(`${API_URL}/token`, { withCredentials: true });
};

export const login = (email, password) => {
  return axios.post(
    `${API_URL}/auth`,
    { email, password },
    { withCredentials: true }
  );
};

export const googleAuth = (access_token) => {
  return axios.post(
    `${API_URL}/auth/google`,
    { access_token },
    { withCredentials: true }
  );
};

export const facebookAuth = (access_token) => {
  return axios.post(
    `${API_URL}/auth/facebook`,
    { access_token },
    { withCredentials: true }
  );
};

export const register = (email, username, password) => {
  return axios.post(
    `${API_URL}/register`,
    { email, username, password },
    { withCredentials: true }
  );
};

export const logout = () => {
  return jwtInstance.get(`${API_URL}/logout`, { withCredentials: true });
};

export const sendEmailConfirmEmail = () => {
  return jwtInstance.post(`${API_URL}/email-confirm`);
};

export const emailConfirm = (token) => {
  return axios.post(`${API_URL}/email-confirm/${token}`);
};

export const sendPasswordResetEmail = (email) => {
  return axios.post(`${API_URL}/password/reset`, { email });
};

export const resetPassword = (email, password, token) => {
  return axios.post(`${API_URL}/password`, { email, password, token });
};

export const getCurrentUser = () => {
  return jwtInstance.get(`${API_URL}/auth`);
};

export const getUser = (userId) => {
  return jwtInstance.get(`${API_URL}/users/${userId}`);
};

export const getUsersInfo = () => {
  return jwtInstance.get(`${API_URL}/users/info`);
};

export const updateUser = (userId, userData) => {
  return jwtInstance.patch(`${API_URL}/users/${userId}`, userData);
};

export const changePassword = (userId, oldPassword, newPassword) => {
  return axios.post(
    `${API_URL}/users/${userId}/password`,
    {
      oldPassword,
      password: newPassword,
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
};

export const getEvents = () => {
  return jwtInstance.get(`${API_URL}/events`);
};

export const createEvent = (name) => {
  return jwtInstance.post(`${API_URL}/events`, { name });
};

export const deleteEvent = (eventId) => {
  return jwtInstance.delete(`${API_URL}/events/${eventId}`);
};

export const addEventMember = (eventId, userId) => {
  return jwtInstance.post(`${API_URL}/events/${eventId}/members/${userId}`);
};

export const deleteEventMember = (eventId, userId) => {
  return jwtInstance.delete(`${API_URL}/events/${eventId}/members/${userId}`);
};

export const updateEventMember = (eventId, userId, isAdmin) => {
  return jwtInstance.put(`${API_URL}/events/${eventId}/members/${userId}`, {
    isAdmin,
  });
};

export const findUser = (username) => {
  return jwtInstance.post(`${API_URL}/users/find`, { username });
};

export const getEvent = (eventId) => {
  return jwtInstance.get(`${API_URL}/events/${eventId}`);
};

export const updateEvent = (eventId, eventData) => {
  return jwtInstance.patch(`${API_URL}/events/${eventId}`, eventData);
};

export const getEventsInfo = () => {
  return jwtInstance.get(`${API_URL}/events/info`);
};

export const getPoints = (eventId) => {
  return jwtInstance.get(`${API_URL}/events/${eventId}/points`);
};

export const getPointsSummary = (eventId) => {
  return jwtInstance.get(`${API_URL}/events/${eventId}/points/summary`);
};

export const givePoints = (
  eventId,
  fromMemberId,
  toMemberId,
  transactionAmount,
  message,
  hasAudio,
  hasImage,
  imageType
) => {
  return jwtInstance.post(`${API_URL}/events/${eventId}/points`, {
    to_member: toMemberId,
    message,
    transaction_amount: transactionAmount,
    from_member: fromMemberId,
    has_audio: hasAudio,
    has_image: hasImage,
    image_type: imageType,
  });
};

export const getFeed = () => {
  return jwtInstance.get(`${API_URL}/feed`);
};

export const registerNotificationSubscription = (subscription) => {
  return jwtInstance.post(`${API_URL}/notifications/subscriptions`, {
    subscription: JSON.stringify(subscription),
  });
};

export const getLastActivity = () => {
  return jwtInstance.get(`${API_URL}/activity`);
};

export function uploadMedia(file, uploadUrl) {
  let formData = new FormData();
  formData.append("policy", uploadUrl.fields["policy"]);
  formData.append("x-amz-algorithm", uploadUrl.fields["x-amz-algorithm"]);
  formData.append("x-amz-credential", uploadUrl.fields["x-amz-credential"]);
  formData.append("x-amz-date", uploadUrl.fields["x-amz-date"]);
  if (!!uploadUrl.fields["x-amz-security-token"]) {
    formData.append(
      "x-amz-security-token",
      uploadUrl.fields["x-amz-security-token"]
    );
  }
  formData.append("x-amz-signature", uploadUrl.fields["x-amz-signature"]);
  formData.append("key", uploadUrl.fields["key"]);
  formData.append("file", file);

  return axios.post(uploadUrl.url, formData, {
    headers: { "Content-Type": file.type },
  });
}
