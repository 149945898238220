import {
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Box,
  Avatar,
  Fab,
  Zoom,
  TextField,
  Modal,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import LogoutIcon from "@mui/icons-material/Logout";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

import { useState, useContext } from "react";
import { Stack } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createEvent } from "../utils/api";

import { useAuth } from "../utils/auth";
import { UserDataContext } from "../utils/userData";

export default function LeftMenu({ open, onClose }) {
  const { isAdmin, logout } = useAuth();
  const { user, groups, reloadUserData } = useContext(UserDataContext);
  const theme = useTheme();
  const largeScreen = useMediaQuery("(min-width:1000px)");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const drawerWidth = "350px";

  const onModalClose = () => {
    setModalIsOpen(false);
    reloadUserData();
    onClose();
  };

  return (
    <>
      <CreateGroupModal open={modalIsOpen} onClose={onModalClose} />
      <CssBaseline />

      <Drawer
        variant={largeScreen ? "permanent" : "temporary"}
        open={largeScreen || open}
        onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        onClick={onClose}
        sx={{
          height: "100%",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <div>
          <Box
            sx={{
              height: "150px",
              backgroundColor: theme.palette.primary.main,
              padding: theme.spacing(2),
              display: "flex",
              //   justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={2}>
              <IconButton onClick={handleMenu}>
                <Avatar
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    color: "black",
                    width: 48,
                    height: 48,
                    cursor: "pointer",
                  }}
                >
                  {user ? user.username.slice(0, 2).toUpperCase() : ""}
                </Avatar>
              </IconButton>
              <Box
                style={{
                  ...theme.typography.title,
                  fontSize: 24,
                  height: "64px",
                  lineHeight: "64px",
                }}
              >
                Note Tes Potes.
              </Box>
            </Stack>
          </Box>
        </div>
        <List disablePadding>
          {isAdmin && (
            <UnstyledLink to="/admin">
              <Active activeUrl="/admin">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Admin"} />
                  </ListItemButton>
                </ListItem>
              </Active>
            </UnstyledLink>
          )}
          <UnstyledLink to="/">
            <Active activeUrl="/">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Accueil"} />
                </ListItemButton>
              </ListItem>
            </Active>
          </UnstyledLink>

          <UnstyledLink to="/feed">
            <Active activeUrl="/feed">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Actualités"} />
                </ListItemButton>
              </ListItem>
            </Active>
          </UnstyledLink>
        </List>
        <Divider />
        <div style={{ flexGrow: 1, overflowY: "scroll" }}>
          {!groups && <LinearProgress sx={{ height: "2px" }} />}
          {!!groups && (
            <List disablePadding>
              {groups.map((group) => (
                <UnstyledLink
                  to={`/group/${group.eventId}`}
                  key={group.eventId}
                >
                  <Active activeUrl={`/group/${group.eventId}`} exact={false}>
                    <ListItem disablePadding>
                      <ListItemButton>
                        {/* <ListItemIcon>
                            <NotificationsIcon />
                          </ListItemIcon> */}
                        <ListItemText primary={group.name} />
                      </ListItemButton>
                    </ListItem>
                  </Active>
                </UnstyledLink>
              ))}
            </List>
          )}
        </div>
        <div style={{ textAlign: "right", padding: theme.spacing(3) }}>
          <Zoom in={true}>
            <Fab
              color="primary"
              aria-label="add"
              onClick={(e) => {
                e.stopPropagation();
                setModalIsOpen(true);
              }}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        </div>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "right",
        // }}
        keepMounted
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={() => {
          if (Boolean(anchorEl)) {
            handleClose();
          }
        }}
      >
        <UnstyledLink to="/profile">
          <MenuItem>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Profil</ListItemText>
          </MenuItem>
        </UnstyledLink>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Se déconnecter</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

function UnstyledLink({ children, to }) {
  return (
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
      {children}
    </Link>
  );
}

function CreateGroupModal({ open, onClose }) {
  const theme = useTheme();
  const largeScreen = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const onModalFormChange = (event) => {
    setModalFormValue((formValue) => {
      formValue = { ...formValue, [event.target.name]: event.target.value };

      return formValue;
    });
  };

  const onModalSubmit = (event) => {
    event.preventDefault();
    if (!!modalFormValue.name) {
      createEvent(modalFormValue.name).then((res) => {
        onClose();
        navigate(`/group/${res.data.eventId}`);
      });
    }
  };

  const [modalFormValue, setModalFormValue] = useState({
    name: "",
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: largeScreen ? 400 : 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px / 10px",
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: theme.spacing(1) }}>
          Crée un groupe
        </Typography>
        <form onChange={onModalFormChange} onSubmit={onModalSubmit}>
          <TextField
            label="Nom du groupe"
            variant="standard"
            name="name"
            fullWidth
            autoComplete="off"
          />
          <div style={{ marginTop: theme.spacing(2) }}>
            <Button
              variant="text"
              type="submit"
              disabled={!modalFormValue.name}
            >
              Créer
            </Button>
            <Button
              variant="text"
              onClick={onClose}
              style={{ marginLeft: "10px" }}
              color="error"
            >
              Annuler
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

function Active({ activeUrl, children, exact = true }) {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Box
      sx={{
        bgcolor: (
          exact
            ? location.pathname === activeUrl
            : location.pathname.startsWith(activeUrl)
        )
          ? theme.palette.primary.light
          : "white",
      }}
    >
      {children}
    </Box>
  );
}
